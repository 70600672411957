
import { computed, defineComponent, ref } from "vue";
// @ is an alias to /src
import { useStore } from "@/store";
import { downloadSVGasPNG, downloadText } from "@/assets/utils";
import { statisticsCSV } from "@/data/statisticsCSV";
import ErrorPopup from "@/components/ErrorPopup.vue"
// import { NWK } from "@/data/NWK";
import de from "@/de";
import en from "@/en";

export default defineComponent({
  components: { ErrorPopup },
  mixins: [de, en],
  methods: {
    t(prop: string) {
      return this[document.documentElement.lang][prop];
    },
  },
  setup(props, { emit }) {
    const menuOpen = ref(false);
    const showErrorPopup = ref(false)

    const store = useStore();

    const newNWK = () => {
      store.commit("newNWK");
      emit("new-nwk");
    };

    const closeErrorPopup = () => {
      showErrorPopup.value = false;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const open = (event: any) => {
      // based on https://stackoverflow.com/a/36198572/1140589
      const files = event.target.files;

      if (files.length <= 0) {
        return false;
      }

      const fr = new FileReader();
      // eslint-disable-next-line
      fr.onload = (e: any) => {
        let nwkText = e.target.result;
        // TODO format checks & error messages
        // if (savedNWK.alteri && savedNWK.alteri instanceof Array) {
        // if (savedNWK.ego && isEgo(savedNWK.ego)) {
        try {
          JSON.parse(nwkText);
          store.commit("loadJSON", nwkText);
          emit("open-nwk");
        } catch (error) {
          // eslint-disable-next-line
            fr.onload = (e: any) => {
              nwkText = e.target.result;
              try {
              store.commit("loadJSON", nwkText);
              emit("open-nwk");
            } catch (error) {
              showErrorPopup.value = true;
            }
          };
          fr.readAsText(files.item(0), "windows-1252")
        }
      };
      fr.readAsText(files.item(0));
    };

    const currentVersion = computed(() => store.state.record.currentVersion);

    const visibleNWKVersion = computed(() =>
      store.state.record.versions.find(
        (version) => version.id === currentVersion.value
      )
    );

    const save = () => {
      store.commit("prepareToSaveJSON");

      let nwkJSON;
      let filename = store.state.nwk.ego.name;
      let pseudonymizedVersions = [];

      if (store.state.pseudonym.active) {
        for (let i = 0; i < store.state.record.versions.length; i++) {
          let tempNWK = JSON.parse(
            JSON.stringify(store.state.record.versions[i].nwk)
          );
          for (const alter of tempNWK.alteri) {
            alter.name = store.getters["pseudonym/pseudonize"](alter.id);
          }
          // clone version and replace nwk with pseudonymized nwk
          let pseudonymizedVersion = {
            ...store.state.record.versions[i],
            nwk: tempNWK,
          };
          pseudonymizedVersions.push(pseudonymizedVersion);
        }

        let anonymizedRecord = {
          ...store.state.record,
          versions: pseudonymizedVersions,
        };
        nwkJSON = JSON.stringify(anonymizedRecord);
        filename += "_anonym";
      } else {
        nwkJSON = JSON.stringify(store.state.record);
        console.log("non anonym: " + nwkJSON);
      }

      downloadText(filename + ".json", nwkJSON);
    };

    const openDemoData = () => {
      const DEMO_URL = "Rosa_Braunsteigl-Mueller.json";
      fetch(DEMO_URL)
        .then((res) => res.text())
        .then((nwkText) => {
          store.commit("loadJSON", nwkText);
          emit("open-nwk");
        })
        .catch((err) => {
          throw err;
        });
    };

    return {
      menuOpen,
      newNWK,
      open,
      showErrorPopup,
      closeErrorPopup,
      save,
      openDemoData,
      appVersion: computed(() => process.env.VUE_APP_VERSION),
      exportPNG: () => {
        downloadSVGasPNG(
          store.state.nwk.ego.name +
            " " +
            visibleNWKVersion.value?.title +
            " " +
            visibleNWKVersion.value?.date?.substring(8, 10) +
            "." +
            visibleNWKVersion.value?.date?.substring(5, 7) +
            "." +
            visibleNWKVersion.value?.date?.substring(0, 4) +
            ".png",
          "svg#nwkmap"
        );
      },
      exportCSV: () => {
        const today = new Date();
        downloadText(
          store.state.nwk.ego.name +
            " " +
            today.toLocaleDateString("en-CA") +
            ".csv",
          statisticsCSV(store.state.record.versions)
        );
      },

      pseudonyms: computed(() => store.state.pseudonym.active),
      togglePseudonyms: () => store.commit("pseudonym/toggle"),
      horizons: computed(() => store.state.view.horizons),
      connections: computed(() => store.state.view.connections),
      alteriNames: computed(() => store.state.view.alteriNames),
      showAge: computed(() => store.state.view.ageInNwk),
      showRole: computed(() => store.state.view.roleInNwk),
    };
  },
});
