
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";
import { findCliques } from "@/data/NetworkClustering";
import { sectorIndex } from "@/data/AlterCategories";
import de from "@/de";
import en from "@/en";
import { Alter } from "@/data/Alter";
import { Sectors, SectorsEng } from "@/data/Sectors";

interface CliqueItem {
  alters: Alter[];
  description: string;
}

export default defineComponent({
  mixins: [de, en],
  methods: {
    t(prop: string) {
      return this[document.documentElement.lang][prop];
    },
  },
  setup() {
    const store = useStore();

    const sectorLabels =
      document.documentElement.lang === "de" ? Sectors : SectorsEng;

    const cliques = computed((): Alter[][] => {
      return findCliques(store.state.nwk);
    });

    const cliquesList = computed((): CliqueItem[] => {
      return cliques.value.map((clique) => {
        const sectorIndices = clique
          .map((a) => sectorIndex(a))
          .filter((d) => d !== null);
        const uniqSectorIndices = [...new Set(sectorIndices)];
        console.log(uniqSectorIndices);
        const sectLabels = uniqSectorIndices
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map((d) => sectorLabels[d!])
          .join(", ");

        return {
          // cliqueNumber: `Clique ${idx + 1}`,
          alters: clique,
          description: clique.length + " (" + sectLabels + ")",
        };
      });
    });

    const clickClique = (alteri: Alter[]) => {
      if (alteri.length > 0) {
        store.commit(
          "session/selectAlters",
          alteri.map((a) => a.id)
        );
      }
    };

    return {
      cliquesList,
      clickClique,
      isSelected: store.getters["session/isSelected"],
    };
  },
});
